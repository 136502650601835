<template>
  <div>
    <b-overlay :show="show" opacity="0.95" variant="dark">
      <b-row>
        <b-col md="12" xl="7" lg="7">
          <b-row>
            <b-col cols="12">
              <b-card>
                <b>ตั้งค่าแบรนด์เพจ</b>

                <b-row>
                  <b-col cols="6" style="margin-top: 2em">
                    <div align="center">
                      <b-form-checkbox
                        checked="true"
                        name="check-button"
                        switch
                        v-model="name_check"
                        inline
                        >ชื่อแบรนด์เพจ</b-form-checkbox
                      >
                      <hr />
                      <br />
                      <b> </b>
                      <b-form-input
                        class="form-control"
                        type="text"
                        name="name"
                        id="name"
                        v-model="name"
                      />
                    </div>
                  </b-col>
                  <b-col cols="4" style="margin-top: 2em">
                    <div align="center">
                      <b-form-checkbox
                        checked="true"
                        name="check-button"
                        switch
                        v-model="logo_check"
                        inline
                        >logo</b-form-checkbox
                      >
                      <hr />
                      <b-avatar style="width: 50px; height: 50px" :src="logo" />
                    </div>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col cols="12" style="margin-top: 2em">
                    Background
                    <b-form-file
                      type="file"
                      accept=".jpeg,.png,.jpg,GIF"
                      :hidden="true"
                      @change="onFileChange"
                    />
                    <br />
                    <div align="center">
                      <!-- <img :src="background" width="480" /> -->
                    </div>
                  </b-col>
                </b-row>
                <div>
                  <br /><br />
                  <b-button variant="primary" @click="save()">
                    <span>บันทึก</span>
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="12" xl="5" lg="5">
          <previwe ref="datapreviwe"></previwe>
        </b-col>
      </b-row>
      <template v-slot:overlay>
        <div class="overlay-alert">กำลังโหลด...</div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BListGroup,
  BListGroupItem,
  VBModal,
  BAvatar,
  BInputGroup,
  BInputGroupPrepend,
  BAlert,
  BFormCheckbox,
  BOverlay,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import { heightFade } from "@core/directives/animations";
import previwe from "./preview.vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BModal,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BAlert,
    BFormCheckbox,
    ToastificationContent,
    BFormFile,
    BOverlay,
    previwe,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      show: false,
      id: "",
      name: "",
      name_check: true,
      logo: "",
      logo_check: true,
      background_check: "",
      background: "",
      file_background: "",
    };
  },

  mounted() {
    // if (localStorage.getItem("packages") === "Pro") {
      this.show = false;
    // } else if (localStorage.getItem("packages") === "Standard") {
    //   this.show = false;
    // } else {
    //   this.show = true;

    // }
    this.loaddata();
  },

  watch: {
    name: {
      handler: function (val, oldVal) {
        console.log("oldVal :>> ", oldVal);
        console.log("val :>> ", val);
        this.$refs.datapreviwe.set_display("brand-name", "", val);
      },
      deep: true,
    },
    name_check: {
      handler: function (val, oldVal) {
        // console.log("oldVal :>> ", oldVal);
        // console.log("val :>> ", val);
        // this.$refs.datapreviwe.store_name = val
        if (val === false || val === "false") {
          this.$refs.datapreviwe.set_display("brand-name", "none", "");
        } else {
          this.$refs.datapreviwe.set_display("brand-name", "", this.name);
          // this.$refs.datapreviwe.store_name = this.name;
          console.log("this.name :>> ", this.name);
        }
      },
      deep: true,
    },
    logo_check: {
      handler: function (val, oldVal) {
        console.log("oldVal :>> ", oldVal);
        console.log("val :>> ", val);
        if (val === false || val === "false") {
          this.$refs.datapreviwe.set_display("brand-logo", "none", "");
        } else {
          this.$refs.datapreviwe.set_display("brand-logo", "", "");
        }
      },
      deep: true,
    },
  },
  methods: {
    async loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("get_brandpages", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          if (response.data.brandpages) {
            this.id = response.data.brandpages.id;
            this.name = response.data.brandpages.name;
            this.logo = response.data.brandpages.logo
              ? response.data.brandpages.logo
              : localStorage.getItem("logo");
            if (!response.data.brandpages.name) {
              this.name_check = "false";
              // this.$refs.datapreviwe.set_display("brand-name", "none");
            }
            if (!response.data.brandpages.logo) {
              this.logo_check = "false";
              // this.$refs.datapreviwe.set_display("brand-logo", "none");
            }
            this.background = response.data.brandpages.background;
            this.$refs.datapreviwe.set_backgroundImage(
              response.data.brandpages.background
                ? response.data.brandpages.background
                : "https://static.socifi.com/27/7832/26581/68832/angry-birds-guide-wallpaper-2.jpg"
            );
          } else {
            this.name = response.data.lineoa.name;
            this.logo = response.data.lineoa.logo;
          }
        });
    },
    onFileChange(e) {
      this.file_background = e.target.files[0];
      console.log("file_background", this.file_background);
      const image = e.target.files[0];
      this.background = URL.createObjectURL(image);
      console.log("this.background :>> ", this.background);
      this.$refs.datapreviwe.set_backgroundImage(this.background);
    },

    save() {
      let formData = new FormData();

      formData.append("id", this.id);
      formData.append("name_check", this.name_check);
      formData.append("logo_check", this.logo_check);
      formData.append("logo", this.logo);
      formData.append("name", this.name);
      formData.append("file_background", this.file_background);
      formData.append("background", this.background);
      formData.append("store_id", localStorage.getItem("store_id"));
      api
        .post("add_brandpages", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "เพิ่มข้อมูล",
                icon: "SaveIcon",
                text: "บันทึกข้อมูลของคุณเรียบร้อย",
                variant: "success",
              },
            },
            {
              timeout: 4000,
            }
          );
          this.loaddata();
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: " โปรดลองใหม่อีกครั้ง!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>

<style>
.overlay-alert{
  color: #fff;
  font-size: 20px;
}
</style>
