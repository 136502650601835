<template>
  <div >
    <div id="iphone">
      <div id="screen">
        <div id="notch" style="background-color: #000">
          <div style="margin-left: -40px; font-size: 12px; padding-top: 5px">
            <p style="color: #000; margin-right: 10em">Dtac</p>
          </div>
          <div id="speaker" style="margin-top: -40px"></div>
        </div>
        <div id="stat-bar" style="margin-top: -5px">
          <feather-icon
            icon="WifiIcon"
            style="color: #000"
            class="mr-50"
            size="15"
          />
          <feather-icon
            icon="BatteryChargingIcon"
            style="color: #000"
            class="mr-90"
            size="15"
          />
        </div>
        <div class="header_link">
          <img :src="require('@/assets/Icon.png')" />
          &nbsp;&nbsp;hotspot.socioWifi.co.th
        </div>
        <div class="header_link">hotsport</div>
        <div style="height: 60px" class="header_wifi">
          <div>
            <feather-icon
              icon="ChevronLeftIcon"
              style="color: #bebdc2"
              class="mr-90"
              size="28"
            />
            <feather-icon
              icon="ChevronRightIcon"
              style="color: #bebdc2"
              class="mr-90"
              size="28"
            />
          </div>
          <h4><b>เข้าสู่ระบบ</b></h4>
          <h4 style="color: #1d7bf7">ยกเลิก&nbsp;&nbsp;</h4>
        </div>
        <hr />
        <div class="body-captive" id="body-captive">
          <div class="header">
            <span class="logo2" style="margin-top: -0.7em">
              
            </span>
            <span class=""> Free Wireless Internet </span>
          </div>
          <div class="body-data">
            <div class="mb-5">&nbsp;</div>
            <div class="mb-5">&nbsp;</div>
            <div class="mb-2">&nbsp;</div>
            <div align="center">
              <img id="brand-logo" class="brand-logo" :src="logo" />
              <div id="space1" style="display: none; height: 85px" class="mb-4">
                &nbsp;
              </div>
              <h1 id="brand-name" class="name">{{ store_name }}</h1>
              <div id="space2" style="display: none" class="mb-3">&nbsp;</div>
              <br /><br />
              <div class="box">
                <br />

                <div align="center">
                  <div id="btn_login_line">
                    <div @click="login()">
                      <div class="line-text mt-50">
                        
                        <span style="font-size: 16px; color: #fff">
                          เชื่อมต่อไวไฟ</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>

      <div align="center" style="margin-top: 1em"></div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCard,
  BCardHeader,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BBadge,
  BCardBody,
  BFormSelect,
  BAlert,
  BFormFile,
  BImgLazy,
  BTabs,
  BTab,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import api from "@/api";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BCard,
    BCardHeader,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BBadge,
    BCardBody,
    BFormSelect,
    BAlert,
    BFormFile,
    Swiper,
    SwiperSlide,
    BImgLazy,
    BTabs,
    BTab,
    BNavItemDropdown,
    BDropdownItem,
  },

  mixins: [togglePasswordVisibility],
  data() {
    return {
      items: [
        {
          id: 1,
          number_of_pictures: 1,
          options: [
            { value: 1, text: "1" },
            { value: 2, text: "2" },
            { value: 3, text: "3" },
          ],
          pictrue1: require("@/assets/images/card_message/default.png"),
          pictrue2: require("@/assets/images/card_message/default.png"),
          pictrue3: require("@/assets/images/card_message/default.png"),
          text_banner: "banner",
          name: "",
          tag1: "tag1",
          tag2: "tag2",
          tag3: "tag3",
          description: "description",
          link: "link",
          price: "999",
          type: "สินค้า",
          setclassBanner: "a5",
          setclassTag: "",
          tag_class1: "a2",
          tag_class2: "a3",
          tag_class3: "a5",
        },
        {
          id: 2,
          number_of_pictures: 1,
          options: [
            { value: 1, text: "1" },
            { value: 2, text: "2" },
            { value: 3, text: "3" },
          ],
          pictrue1: require("@/assets/images/card_message/default.png"),
          pictrue2: require("@/assets/images/card_message/default.png"),
          pictrue3: require("@/assets/images/card_message/default.png"),
          text_banner: "banner",
          name: "",
          tag1: "tag1",
          tag2: "tag2",
          tag3: "tag3",
          description: "description",
          link: "link",
          price: "999",
          type: "สินค้า",
          setclassBanner: "a5",
          setclassTag: "",
          tag_class1: "a2",
          tag_class2: "a3",
          tag_class3: "a5",
        },
      ],
      items_end: [],
      swiperOptions: {
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
        },
      },
      type_card: "สินค้า",
      store_name: localStorage.getItem("store_name"),
      logo: localStorage.getItem("logo"),
      isCaptive: false,
      isSamsung: false,
      liff: "",
      status: "",
      password: "",
      userEmail: "",
      required,
      email,
      name_user: "",
      mac: null,
      hotspot: null,
      data_store: null,
      brach_id: null,
      branch_name: null,
      store_id: null,

      branch_pictrue: null,
      state: null,
      state_socio: null,
      setLogin: false,
      display: "display: none",
      app_ch_id: null,
      app_ch_secret: null,
      lineoa_id: null,
      ch_id: null,
      ch_secret: null,
      ch_token: null,
      href: null,
      youLiked: false,
      Liked: false,
      total_Liked: 30,
      count: 0,
      show_clickTab1: false,
      show_clickTab2: false,
      show_clickTab3: false,
    };
  },
  directives: {
    Ripple,
  },

  created() {},
  mounted() {
    this.youLiked = true;
    this.Liked = true;
    this.close();
    this.store_name = localStorage.getItem("store_name");
  },
  methods: {
    data_preview(items, items_end, type_card) {
      this.items = [];
      this.items = items;
      this.type_card = type_card;
      this.$forceUpdate();
    },
    set_backgroundImage(img) {
      document.getElementById("body-captive").style.backgroundImage =
        "url('" + img + "')";
    },

    set_display(id, style, text) {
      if (id === "brand-logo") {
        if (style === "none") {
          document.getElementById("brand-logo").src =
            "https://api.socio.co.th/images/transparent.png";
        } else {
          document.getElementById("brand-logo").src = this.logo;
        }
      } else {
        if (style === "none") {
          document.getElementById("brand-name").innerHTML = "&nbsp;";
        } else {
          document.getElementById("brand-name").innerHTML = text;
        }
      }
      // document.getElementById(id).style.display = style;
      // if(id === 'brand-logo' && style === 'none'){

      //   document.getElementById("space1").style.display = "";
      // }else{
      //   document.getElementById("space1").style.display = "none";
      // }
      // if (id === 'brand-name'&& style === 'none') {
      //   document.getElementById("space2").style.display = "";
      // }else{
      //   document.getElementById("space2").style.display = "none";

      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";

.line-text {
  margin-left: -15px;
}
.body-captive {
  background-image: url("https://static.socifi.com/27/7832/26581/68832/angry-birds-guide-wallpaper-2.jpg");
  height: 627px;
  margin-top: -10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.header {
  font-size: 12px;
  font-weight: 700;
  //position: fixed;
  z-index: 1011;
  overflow: hidden;
  //width: 378px !important;
  text-align: center;
  color: #000000;
  line-height: 40px;
  background-color: rgba(255, 255, 255, 0.8);
}
.header > .logo2 {
  position: absolute;
  width: 81px;
  text-indent: none !important;
}

.header > .logo2 {
  margin-left: -10em;
  height: 60px;
  left: 1;
}
.brand-logo {
  border-radius: 50%;
  width: 120px;
}
.name {
  font-size: 50px;
  text-indent: -0.03em;
  color: #fff;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 85%);
  margin: 15px 0;
  display: block;
  font-weight: 400;
  font-size: 24px;
}

.box {
  position: relative;
  max-width: 600px;
  margin-top: 20px;
  margin: 20px;
  padding: 20px;
  text-align: center;
  color: #fff;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flexbox;
  bottom: 0;
}

.header_wifi {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_link {
  display: flex;
  justify-content: center;
  align-items: center;
}

#gesture-bar {
  background: rgb(144, 144, 144);
  width: 30%;
  height: 5px;
  border-radius: 5px;
  margin-left: 35%;
  display: flex;
  align-items: center;
  transition: all 1s;

  &:hover {
    cursor: pointer;
    background: black;
  }
}

#container {
  display: flex;
}

#settings {
  padding: 16px;
  border-radius: 5px;
  width: 30%;

  p {
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.54);
  }

  .round-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .round {
    width: 36px;
    height: 36px;
    border-radius: 5px;
    margin-right: 16px;
    margin-bottom: 16px;

    &:hover {
      cursor: pointer;
    }
  }
}

#iphone {
  $body-color: #000;
  margin-left: -20px;
  font-family: Arial;
  margin: auto;
  width: 410px;
  height: 812px;
  background: $body-color;
  border-radius: 50px;
  box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.9);
  padding: 16px;

  #screen {
    // background: linear-gradient(180deg, #07acbf, #34fdd3);
    background: #fff;
    color: #000;
    height: 100%;
    border-radius: 40px;
    box-shadow: 0px 0px 2px 7px rgba(255, 255, 255, 0.05);

    #stat-bar {
      display: flex;
      align-items: center;
      position: relative;
      left: 80%;
      top: -12px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }

      img:nth-child(1) {
        width: 18px;
        height: 8px;
      }

      img:nth-child(3) {
        width: 20px;
      }
    }

    #notch {
      background: $body-color;
      height: 25px;
      width: 55.7%;
      margin: auto;
      border-radius: 0px 0px 20px 20px;

      #speaker {
        width: 22%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.4),
          rgba(255, 255, 255, 0.4)
        );
        height: 4px;
        border-radius: 5px;
        margin: auto;
        position: relative;
        top: 6px;
      }
    }

    #content {
      color: #000;
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-top: 20px;
      }
      #lock-icon {
        width: 30px;
        margin-top: 24px;
      }

      #time {
        color: white;
        font-size: 70px;
        margin-top: 8px;
      }

      #date {
        color: white;
        font-size: 16px;
      }
    }

    #actions {
      width: 85%;
      display: flex;
      justify-content: space-between;
      position: relative;
      top: 64%;
      margin: auto;

      .action-bg {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 1s;

        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.4);
        }

        img {
          width: 24px;
        }
      }

      .action-bg:nth-child(1) {
        img {
          transform: rotateZ(-45deg);
        }
      }
    }
  }
}

@media screen and (max-width: 567px) {
  #container {
    flex-direction: column;
  }

  #settings {
    width: 100%;
  }
}
</style>
